import React from 'react';

class SearchPreloader extends React.Component {
	constructor(props) {
		super(props);
		
		let $plDiv = document.createElement('div');
		
		const $styl = {
			display: 'flex',
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: 'rgba(0,0,0,0.5)',
			color: '#FFF',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 19999
		};
		Object.keys($styl).map($key => {
			$plDiv.style[$key] = $styl[$key];
		});
		
		$plDiv.innerHTML = '<div class="preloader-inner" style="width: 200px; height: 200px; background: transparent;">' +
			'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  display: block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">\n' +
			'<circle cx="50" cy="50" r="32" stroke-width="8" stroke="#fe1e28" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">\n' +
			'  <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>\n' +
			'</circle>\n' +
			'<circle cx="50" cy="50" r="23" stroke-width="8" stroke="#821911" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">\n' +
			'  <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>\n' +
			'</circle>\n' +
			'</svg></div>';
		
		
		document.body.appendChild($plDiv);
		this.state = {
			div: $plDiv
		};
		
	}
	
	componentWillUnmount() {
		this.state.div.style.display = 'none';
		document.body.removeChild(this.state.div);
	}
	
	componentWillMount() {
		this.state.div.style.display = 'flex';
	}
	
	render() {
		return null;
	}
}

export default SearchPreloader;