import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import SearchPreloader from './components/search_v3/SearchPreloader';

const SearchResultPreloader = React.lazy(() => import('./components/search/SearchResultPreloader'));
const MenuLauncher = React.lazy(() => import('./components/menu/MenuLauncher'));
const MenuLauncherV2 = React.lazy(() => import('./components/menu/MenuLauncherV2'));
const JustIconsDisplay = React.lazy(() => import('./components/menu/JustIconsDisplay'));
const DealerCabinetAdmin = React.lazy(() => import('./components/dealer_cabinet/DealerCabinetAdmin'));
const SearchPageV4 = React.lazy(() => import('./components/search_v4/SearchPageV4'));
const AssistedSearchLauncher = React.lazy(() => import('./components/assisted_search_field/AssistedSearchLauncher'));


if (typeof window.TS_JetKitInitialized === 'undefined') {
	window.TS_JetKitInitialized = true;
	
	window.jQuery(() => {
		
		const $initializers = [
			{
				selector: '.TJS_JetsterMenu_Cont',
				exec: $doc => {
					const $dataPack = JSON.parse($doc.dataset.firstSections);
					
					const isNewLauncher = (document.querySelector('.tjs-flag_use-new-burger') != null);
					
					if (isNewLauncher) {
						ReactDOM.render(
							<React.StrictMode>
								<Suspense fallback={<div></div>}>
									<MenuLauncherV2 {...$dataPack}/>
								</Suspense>
							</React.StrictMode>,
							$doc
						);
					} else {
						ReactDOM.render(
							<React.StrictMode>
								<Suspense fallback={<div></div>}>
									<MenuLauncher {...$dataPack}/>
								</Suspense>
							</React.StrictMode>,
							$doc
						);
					}
					
					
				}
			},
			{
				selector: '.TJS_JetsterMenuIcons_Cont',
				exec: $doc => {
					const $dataPack = JSON.parse($doc.dataset.firstSections);
					
					ReactDOM.render(
						<React.StrictMode>
							<Suspense fallback={<div>КАТАЛОГ</div>}>
								<JustIconsDisplay {...$dataPack}/>
							</Suspense>
						</React.StrictMode>,
						$doc
					);
				}
			},
			{
				selector: '.TJS_JetsterSearch_Cont',
				exec: $doc => {
					const $dataPack = JSON.parse($doc.dataset.package);
					
					
					ReactDOM.render(
						<React.StrictMode>
							<Suspense fallback={<div>Поиск...</div>}>
								<SearchResultPreloader {...$dataPack} />
							</Suspense>
						</React.StrictMode>,
						$doc
					);
				}
			},
			{
				selector: '.TJS_AssistedSearchV3_Cont',
				exec: $doc => {
					const $dataPack = JSON.parse($doc.dataset.package);
					
					
					ReactDOM.render(
						<React.StrictMode>
							<Suspense fallback={<SearchPreloader/>}>
								<SearchPageV4 {...$dataPack} />
							</Suspense>
						</React.StrictMode>,
						$doc
					);
				}
			},
			{
				selector: '.TJS_DealerCabinet_Cont',
				exec: $doc => {
					const $dataPack = JSON.parse($doc.dataset.package);
					
					
					ReactDOM.render(
						<React.StrictMode>
							<Suspense fallback={<div>Поиск...</div>}>
								<DealerCabinetAdmin {...$dataPack} />
							</Suspense>
						</React.StrictMode>,
						$doc
					);
				}
			},
			{
				selector: '.TJS_AssistedSearchField_Cont',
				exec: $doc => {
					let $isHeader = false;
					if (window.jQuery($doc).closest('#headerfixed').length > 0) {
						$isHeader = true;
					}
					
					
					ReactDOM.render(
						<React.StrictMode>
							<Suspense fallback={<div>Загрузка...</div>}>
								<AssistedSearchLauncher isHeader={$isHeader}/>
							</Suspense>
						</React.StrictMode>,
						$doc
					);
				}
			}
		];
		
		$initializers.map($arItem => {
			const $elms = document.querySelectorAll($arItem.selector);
			for (var $elm of $elms)
				if ($elm != null) {
					$arItem.exec($elm);
				}
		});
		
	});
	
}


